<template>
  <div>
    <ckeditor :editor="editor" :config="editorConfig" @input="handleInput" :value="editorData"></ckeditor>
    <!-- v-model="editorData" -->
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
//
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment.js'
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink.js'
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter.js'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js'
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js'
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js'
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize.js'
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js'
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js'
import Image from '@ckeditor/ckeditor5-image/src/image.js'
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert.js'
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js'
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock.js'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js'
import Link from '@ckeditor/ckeditor5-link/src/link.js'
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage.js'
import List from '@ckeditor/ckeditor5-list/src/list.js'
// import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle.js'
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed.js'
// import MediaEmbedToolbar from '@ckeditor/ckeditor5-media-embed/src/mediaembedtoolbar.js'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js'
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat.js'
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting.js'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import '@ckeditor/ckeditor5-build-classic/build/translations/zh.js'

export default {
  name: 'editor',
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    content: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  watch: {
    content(val) {
      // watch把資料強制塞回
      this.editorData = val
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: this.content,
      editorConfig: {
        placeholder: this.placeholder,
        plugins: [
          Alignment,
          AutoLink,
          Base64UploadAdapter,
          Bold,
          Essentials,
          FontBackgroundColor,
          FontColor,
          FontSize,
          Heading,
          HorizontalLine,
          Image,
          ImageInsert,
          ImageResize,
          ImageToolbar,
          ImageUpload,
          ImageStyle,
          Indent,
          IndentBlock,
          Italic,
          Link,
          LinkImage,
          List,
          // ListStyle,
          MediaEmbed,
          // MediaEmbedToolbar,
          Paragraph,
          RemoveFormat,
          SourceEditing,
          Underline,
          Table,
          TableToolbar,
        ],
        toolbar: {
          items: [
            'heading',
            'bold',
            'italic',
            'underline',
            'fontBackgroundColor',
            'fontColor',
            'fontSize',
            '|',
            'alignment',
            'bulletedList',
            'numberedList',
            'outdent',
            'indent',
            '|',
            'link',
            'imageUpload',
            'mediaEmbed',
            'insertTable',
            'horizontalLine',
            'undo',
            'redo',
            '|',
            'removeFormat',
            'sourceEditing',
          ],
          shouldNotGroupWhenFull: true,
        },
        image: {
          toolbar: [
            'imageStyle:alignBlockLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignBlockRight',
            '|',
            'imageTextAlternative',
            'linkImage',
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
        heading: {
          options: [
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          ],
        },
        fontSize: {
          options: [12, 14, 18, 24, 30, 36, 48, 60, 72, 96],
        },
        // 影片崁入要設為true
        mediaEmbed: {
          previewsInData: true,
        },
        language: 'zh',
      },
    }
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event)
    },
  },
}
</script>

<style scoped>
::v-deep .ck-editor__editable {
  min-height: 200px;
  max-height: 700px;
}
::v-deep h1,
.ck-heading_heading1 {
  font-size: 24px;
  font-weight: 500;
}
::v-deep h2,
.ck-heading_heading1 {
  font-size: 20px;
  font-weight: 500;
}

::v-deep p,
.ck-heading_paragraph {
  font-size: 14px;
}

::v-deep span {
  font-size: inherit;
}

::v-deep ol {
  padding-left: 2em;
  list-style: auto;
}
::v-deep ul {
  padding-left: 2em;
  list-style: unset;
}

::v-deep hr {
  height: 1px;
}

::v-deep a {
  color: #0000f0;
  text-decoration: underline;
}

::v-deep .table {
  width: 100%;
}

::v-deep .ck-toolbar {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  border-color: var(--gray-border);
}

::v-deep .ck-content {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  border-color: var(--gray-border) !important;
}

::v-deep .ck-heading-dropdown {
  width: 70px;
}
</style>
